import { API } from 'aws-amplify';
import Qs from 'query-string';
import ApiResponse from './ApiResponse';

import type { CollegeNoteDto } from './notes';
import type { AthleteHistoryRanks } from '../component/blocks/athletes/athlete-ranks-history/types';
import { API_ERRORS, DEFAULT_ERROR_MESSAGE } from '../constants';
import { PositionType } from '../helpers/data';
import { TagType } from '../component/blocks/shared/types';
import { OrderType } from '../component/blocks/athletes/Athlete.types';

import { AthleteRankDto, AthleteRankForFiltersList } from './ranks';
import { AthleteTasksDto } from './tasks';

export type TagHistoryInfo = {
  college_tag_id: string;
  is_deleted: boolean;
}
export type HistoryTags = {
  users_id: number;
  coach_name: string;
  date_created: string;
  location: string;
  tournament_id: number;
  tags: TagHistoryInfo[];
}
export interface AthleteListQueryDto extends AthleteFilterDto {
  page?: number;
  page_length?: number;
  tournament_id?: number;
  sort_order?: OrderType;
  sort_by?: string;
  college_program_id?: number;
};

export type AthleteFilterDto = {
  first?: string;
  last?: string;
  club?: string;
  team?: string;
  gradyears?: number[];
  states?: string[];
  positions?: PositionType[];
  tags?: TagType[];
  ranks?: AthleteRankForFiltersList[];
  tasks?: AthleteTaskForFiltersList[];
  note?: string;
  tournament?: { id: number, name: string } | null;
  is_evaluated?: boolean;
  is_evaluated_event?: boolean;
  is_event?: boolean;
  for_export?: boolean;
  for_kanban?: boolean;
  token?: string;
};

export type AthleteTaskForFiltersList = {
  college_task_id: number
  options?: string[]
  assignee?: number[]
}

export type AthleteVideoType = {
  athlete_master_id: number;
  provider_url: string;
  title: string;
  tournament_id: number;
}

export type AthletesListAthleteType = {
  athlete_master_id: number;
  first: string;
  last: string;
  uniform1: number;
  sports_id: number;
  // anonymity: boolean,
  email: string | null;
  height: number;
  gradyear: number;
  scholarship_status: string | null;
  position1: number;
  position1_abbrev: string;
  hcity: string | null;
  hstate: string | null;
  ncsa_client_id: number | null;
  club_name: string | null;
  team_name: string | null;
  ncsa_athlete_token: string | null;
  tags: AthleteTagsResponse[];
  notes: CollegeNoteDto[];
  // need data for athlete details
  birthdate?: string | null;
  phonec?: string | null;
  haddress1?: string | null;
  hzip?: string | null;
  coordinator_name?: string | null;
  coordinator_email?: string | null;
  club_email?: string | null;
  club_director_name?: string | null;
  club_director_email?: string | null;
  club_director_phone?: string | null;
  club_coach_name?: string | null;
  club_coach_email?: string | null;
  club_coach_phone?: string | null;
  position2?: number | null;
  position2_abbrev?: string | null;
  position3_abbrev?: string | null;
  position4_abbrev?: string | null; 
  team_age?: number | null;
  team_rank?: string | null;
  team_master_id?: number | null;
  weight?: number;
  reach?: number;
  approach?: string | null;
  block?: string | null;
  handed?: string | null;
  gpa?: string | null;
  gpa_scale?: string | null;
  psat?: string | null;
  sat_total?: string | null;
  act?: string | null;
  college_attending?: string | null;
  parent1_name?: string | null;
  parent1_email?: string | null;
  parent1_phonec?: string | null;
  parent2_name?: string | null;
  parent2_email?: string | null;
  parent2_phonec?: string | null;
  high_school?: string | null;
  high_school_address?: string | null;
  high_school_city?: string | null;
  high_school_state?: string | null;
  high_school_zip?: string | null;
  hs_uniform_number?: number;
  hs_position_primary?: number;
  hs_position_secondary?: number;
  hs_team_level?: number;
  // data for athlete attending
  attending: AthleteAttendingType[];
  history_tags?: HistoryTags[];
  history_ranks?: AthleteHistoryRanks[];
  videos?: AthleteVideoType[];
  ranks?: AthleteRankDto[];
  tasks: GroupedAthleteTaskType[];
};

export type GroupedAthleteTaskType = {
  college_task_id: number
  task_title: string
} & (
    {
      multiple_options: false
      value: AthleteTasksDto
      values?: void
    } | {
      multiple_options: true
      values: AthleteTasksDto[]
      value?: void
    }
  )

export type AthleteAttendingType = {
  athlete_master_id: number;
  id: string | number | null;
  datestart: string;
  dateend: string;
  event_name: string | null;
  is_local: string;
  provider: string;
  position: number;
  uniform1: number;
  club_name: string;
  team_name: string | null;
};


export type AthleteTagsResponse = {
  athlete_tag_id: number;
  college_tag_id: number;
  athlete_master_id: number;
  tournament_id: number;
  users_id: number | string;
};

export type AthleteTagsType = {
  title: string;
  icon: string;
  color: string;
  primary: boolean;
  tag_type: 'done' | 'omit' | 'default' | null;
  sort_order: number;
} & AthleteTagsResponse;

export type AthletesListDto = {
  athletes: AthletesListAthleteType[];
  total: number;
};

export async function sendAthleteProfileStatistic(athleteId: number) {
  await API.post('legacyAPI', '/athlete-request', {
    body: { athlete_master_id: athleteId },
  })
}

export async function getAthletes(
  params: AthleteListQueryDto = {},
): Promise<ApiResponse<AthletesListDto>> {
  try {
    const queryStringParameters = Qs.stringify({
      ...params,
      positions: params.positions ? params.positions.map(i => i.id) : [],
      tags: params.tags ? params.tags.map(i => i.id) : [],
      tournament_id: params.tournament_id || (params.tournament ? params.tournament.id : 0),
      is_evaluated: !!params.is_evaluated,
      tournament: undefined,
      ranks: params.ranks ? encodeURIComponent(JSON.stringify(params.ranks)) : undefined,
      tasks: params.tasks ? encodeURIComponent(JSON.stringify(params.tasks)) : undefined,
    }, {
      arrayFormat: 'bracket',
      encode: false,
    });

    const data: AthletesListDto = await API.get('legacyAPI', '/athletes?' + queryStringParameters, {});

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    if (error.message === API_ERRORS.UNAUTHORIZED) window.location.pathname = '/';

    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}
