import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Colors } from '../../../theme';
import BlockDetails, { AthleteBlockDetailsType } from './BlockDetails';
import { AthletesListAthleteType } from '../../../../api/athletes';
import AthleteHelpers from '../../../../helpers/AthleteHelpers';
import { useAthleteDetails } from '../../../../hooks/athlete/useAthleteDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 'normal',
      color: Colors.default.secondary,
    },
  }),
);

function AccordionItem(props: { title: string; details: AthleteBlockDetailsType }) {
  const classes = useStyles();

  return (
    <Accordion >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <BlockDetails details={props.details} />
      </AccordionDetails>
    </Accordion>
  );
}

export type AccordionDetailsProps = {
  athlete: AthletesListAthleteType;
  className?: string;
}
const MobileDetails = (props: AccordionDetailsProps) => {
  const { athlete, className } = props;
  const { academic, clubTeam, general, highSchool, parents, physical } = useAthleteDetails();

  return (
    <div className={className}>
      <AccordionItem title={'General'} details={AthleteHelpers.prepareAthleteDetailsData(athlete, general)} />
      <AccordionItem title={'Club/Team'} details={AthleteHelpers.prepareAthleteDetailsData(athlete, clubTeam)} />
      <AccordionItem title={'Physical'} details={AthleteHelpers.prepareAthleteDetailsData(athlete, physical)} />
      <AccordionItem title={'Academic'} details={AthleteHelpers.prepareAthleteDetailsData(athlete, academic)} />
      <AccordionItem title={'Parents'} details={AthleteHelpers.prepareAthleteDetailsData(athlete, parents)} />
      <AccordionItem title={'High School'} details={AthleteHelpers.prepareAthleteDetailsData(athlete, highSchool)} />
    </div>
  );
};

export default MobileDetails;
