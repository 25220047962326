import { Box, Button, makeStyles } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'none',
  },
  activeLink: {
    color: `${theme.palette.primary.main} !important`,
  },
  navBtn: {
    color: '#fff',
    height: '100%',
  }
}));

export default function PublicNavBar() {

  const classes = useStyles();

  const location = useLocation();

  const isFaqPage = location.pathname === '/faq';
  const isLoginPage = location.pathname === '/login';

  return (
    <Box>
      <HashLink smooth to={'/#about'} className={classes.link}>
        <Button className={classes.navBtn}>
          About
        </Button>
      </HashLink>
      <HashLink smooth to={'/#contact'} className={classes.link}>
        <Button className={classes.navBtn}>
          Contact
        </Button>
      </HashLink>
      <Link to='/faq' className={`${classes.link}`}>
        <Button className={`${classes.navBtn} ${isFaqPage ? classes.activeLink : ""}`}>
          FAQ
        </Button>
      </Link>
      <Link to='/login' className={classes.link}>
        <Button className={`${classes.navBtn} ${isLoginPage ? classes.activeLink : ""}`}>
          Login
        </Button>
      </Link>
    </Box>
  )
}