import { API } from 'aws-amplify';

import ApiResponse from './ApiResponse';
import { DEFAULT_ERROR_MESSAGE } from '../constants';

export type AccountInitialSportsDto = {
  college_program_id: number;
  sports_id: number;
};

export type AccountInitialDto = {
  first: string;
  last: string;
  email: string;
  id: number;
  color: string;
  college_name: string;
  college_id: number;
  ncsa_coach_id: number;
  college_program_id: number;
  college_user_id: number;
  sports_id: number;
  sports: AccountInitialSportsDto[];
};

export interface CoachColorDto {
  college_program_id?: number,
  color: string,
}

export interface CollegeCoaches extends CoachColorDto {
  users_id: number,
  initials: string,
  name: string,
  coach: string,
  college_user_id: number,
}



export async function loadInitData(): Promise<ApiResponse<AccountInitialDto>> {
  try {

    const accountInitialDto: AccountInitialDto = await API.get(
      'legacyAPI',
      '/account',
      {}
    );

    return {
      success: true,
      status: 200,
      data: accountInitialDto,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function deleteStaff() {
  try {
    await API.del(
      'legacyAPI',
      `/account`,
      {}
    );

    return {
      success: true,
      status: 200,
    };
  } catch (error) {
    return {
      success: false,
      status: error.response?.status || 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function collegeCoachesList(): Promise<ApiResponse<CollegeCoaches[]>> {
  try {
    const collegeCoachesDto: CollegeCoaches[] = await API.get(
      'legacyAPI',
      '/college-user/colors',
      {}
    );

    return {
      success: true,
      status: 200,
      data: collegeCoachesDto,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}


export async function changeColor(
  coachColorDto: CoachColorDto
): Promise<ApiResponse> {
  try {
    await API.post(
      'legacyAPI',
      '/college-user/color',
      {
        body: { ...coachColorDto },
      }
    );
    return {
      success: true,
      status: 200,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function sportСolor(
  sportsId: number
): Promise<ApiResponse<{ color: string }>> {
  try {
    const sportСolorDto: { color: string } = await API.get(
      'legacyAPI',
      `/college-user/sport-color?sports_id=${sportsId}`,
      {}
    );

    return {
      success: true,
      status: 200,
      data: sportСolorDto
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}