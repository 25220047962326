import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Tab, Tabs } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import BlockDetails from './BlockDetails';
import { AthletesListAthleteType } from '../../../../api/athletes';
import AthleteHelpers from '../../../../helpers/AthleteHelpers';
import { useAthleteDetails } from '../../../../hooks/athlete/useAthleteDetails';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    style: { minWidth: 138 },
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: 160,
  },
}));

export type TabDetailsProps = {
  athlete: AthletesListAthleteType;
  className?: string;
}
const DesktopDetails = (props: TabDetailsProps) => {
  const { athlete, className = '' } = props;
  const [value, setValue] = useState(0);
  const classes = useStyles(props);

  const { academic, clubTeam, general, highSchool, parents, physical } = useAthleteDetails();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={className}>
      <Tabs
        orientation="vertical"
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        className={classes.tabs}
        aria-label="athlete details"
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <Tab label="General" {...a11yProps(0)} />
        <Tab label="Club/Team" {...a11yProps(1)} />
        <Tab label="Physical" {...a11yProps(2)} />
        <Tab label="Academic" {...a11yProps(3)} />
        <Tab label="Parents" {...a11yProps(4)} />
        <Tab label="High School" {...a11yProps(5)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <BlockDetails details={AthleteHelpers.prepareAthleteDetailsData(athlete, general)} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BlockDetails details={AthleteHelpers.prepareAthleteDetailsData(athlete, clubTeam)} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <BlockDetails details={AthleteHelpers.prepareAthleteDetailsData(athlete, physical)} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BlockDetails details={AthleteHelpers.prepareAthleteDetailsData(athlete, academic)} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <BlockDetails details={AthleteHelpers.prepareAthleteDetailsData(athlete, parents)} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <BlockDetails details={AthleteHelpers.prepareAthleteDetailsData(athlete, highSchool)} />
      </TabPanel>
    </div>
  );
};

export default DesktopDetails;
