import { Accordion, AccordionDetails, AccordionSummary, Container, makeStyles, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 16,
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: "white"
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
  },
  sectionHeader: {
    marginTop: 24,
    marginBottom: 8,
    paddingLeft: 16,
  },
  questionBody: {
    fontSize: theme.typography.pxToRem(18),
  }
}));

const faq = [
  {
    section_name: "General Questions",
    children: [
      {
        id: 1,
        header: "What is Coach Packet by NCSA?",
        body: `Coach Packet by NCSA is an event-based app designed to help college coaches and recruiters efficiently scout and evaluate athletes at large events. It provides digital access to comprehensive roster data allowing coaches to make informed decisions on-site.`
      },
      {
        id: 2,
        header: "Who can use Coach Packet?",
        body: "Coach Packet is primarily designed for college coaches, recruiters, and professional scouts."
      },
      {
        id: 3,
        header: "Is Coach Packet free to use?",
        body: "Yes! Coach Packet is free to download, and most events can be attended at no cost. There are no paid fees or services for Coach Packet."
      },
      {
        id: 4,
        header: "Which events are supported by Coach Packet?",
        body: "Coach Packet supports a wide range of athletic events where scouting and recruitment take place. These events can include showcases, tournaments, and combines. We work with 25 sports and host 1,200+ event seach year. Check the app for specific event listings and support."
      },
    ]
  },
  {
    section_name: "Features and Functionality",
    children: [
      {
        id: 5,
        header: "What features does Coach Packet offer?",
        body: <>
          <p>
            Coach Packet offers a suite of features including:
          </p>
          <ul>
            <li>
              Digital access to team rosters linked with NCSA profiles
            </li>
            <li>
              Pre-event schedule access for better planning
            </li>
            <li>
              Custom tagging, icons, and note-taking for athlete evaluations
            </li>
            <li>
              Real-time note sharing and collaboration across coaching staff
            </li>
            <li>
              Side-by-side team roster comparisons
            </li>
            <li>
              Online functionality with automatic data syncing
            </li>
            <li>
              The ability to hide players from view and add players to Front Rush with one click
            </li>
          </ul>
        </>
      },
      {
        id: 6,
        header: "Can I use Coach Packet online?",
        body: "Yes, Coach Packet is designed to work offline! All your data, notes, and changes will be saved locally and automatically synced when you reconnect to the internet."
      },
      {
        id: 7,
        header: "How do I add custom tags and notes for athletes?",
        body: "During an event, select an athlete from the roster and use the tagging feature to add custom tags, icons, and notes. These can be used to track specific skills, needs, or evaluations and are easily accessible for future reference."
      },
      {
        id: 8,
        header: "Can I share notes with other coaches?",
        body: "Yes, Coach Packet allows for seamless collaboration. You can share notes, tags, and evaluations with your coaching staff in real-time. The app automatically syncs this information across devices, ensuring everyone is on the same page."
      },
      {
        id: 9,
        header: "How does the schedule-building feature work?",
        body: "The schedule-building feature allows you to create a personalized event schedule based on the tags and athletes you’ve selected. This helps you prioritize your time and ensures you don’t miss key games or players."
      },
      {
        id: 10,
        header: "What is the 'Hide Player' feature?",
        body: "The 'Hide Player' feature allows you to remove specific athletes from your view, either permanently or just for the current event. This is useful if you’ve already evaluated a player or if they don’t fit your recruiting needs."
      },
      {
        id: 11,
        header: "How do I add a player to Front Rush from Coach Packet?",
        body: `Adding a player to Front Rush is simple. When viewing an athlete’s profile, just click the "Add to Front Rush" button. The athlete’s information will be seamlessly integrated into your Front Rush account.`
      },
    ]
  },
  {
    section_name: "Getting Started and Account Management",
    children: [
      {
        id: 12,
        header: "How do I create an account on Coach Packet?",
        body: "To create an account, download the Coach Packet app from your preferred app store, open it, and follow the on-screen instructions to sign up. You’ll need to provide some basic information, and then you’re ready to start using the app."
      },
      {
        id: 13,
        header: "What should I do if I forget my login details?",
        body: `If you forget your password, click on the "Forgot Password?" link on the login page. You’ll be prompted to enter your email address, and instructions to reset your password will be sent to you.`
      },
      {
        id: 14,
        header: "Can I customize my Coach Packet experience?",
        body: "Yes, you can personalize your experience by setting custom tags, choosing icons, and adjusting notification settings. These preferences help tailor the app to your specific scouting and recruiting needs."
      },
      {
        id: 15,
        header: "How do I update my profile or account information?",
        body: "To update your profile or account information, go to the settings section of the app. From there, you can edit your personal details, change your password, and adjust other account-related settings. For any staff additions or deletions, email us at support@coachpacket.com"
      },
    ]
  },
  {
    section_name: "Technical Support",
    children: [
      {
        id: 16,
        header: "What should I do if I encounter technical issues with the app?",
        body: "If you experience technical difficulties, try restarting the app or your device. You can also try deleting and re-downloading the app. If the issue persists, go to the app and click support and send in a Debug Request. You may also contact us at support@coachpacket.com."
      },
      {
        id: 17,
        header: "Is my data secure with Coach Packet?",
        body: "Yes, your data is protected with industry-standard security measures. Coach Packet ensures that all user information, including notes, tags, and profiles, is encrypted and securely stored. Athletes will never see if you have made a note on them, viewed their profile, or anything else!"
      },
      {
        id: 18,
        header: "How often is Coach Packet updated?",
        body: "Coach Packet is regularly updated to improve performance and add new features. Updates are automatically pushed to the app, so you’ll always have access to the latest version."
      },
    ]
  },
  {
    section_name: "Additional Resources",
    children: [
      {
        id: 19,
        header: "Where can I find tutorials or guides for using Coach Packet?",
        body: <>
          <span>
            You can find video tutorials, user guides, and other resources directly within the app or on the official
            Coach Packet website. You may also find how-to videos on our&nbsp;
            <a
              href="https://www.youtube.com/@CoachPacket"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: '#005DA8' }}
            >
              YouTube page here.
            </a>
          </span>
        </>
      },
    ]
  },
]

export default function FAQ() {

  const classes = useStyles();

  return (
    <main className={classes.mainContainer}>
      <Container maxWidth="lg">
        {
          faq.map(section =>
            <>
              <Typography variant="h4" component={"h4"} className={classes.sectionHeader}>
                {section.section_name}
              </Typography>
              <>
                {
                  section.children.map(child =>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading} >
                          {`${child.id}. ${child.header}`}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.questionBody}>
                          {child.body}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                }
              </>
            </>
          )
        }
      </Container>
    </main>
  )
}