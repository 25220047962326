import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Login from './pages/Login';
import AdminAfterLogin from './pages/AdminAfterLogin';
import Landing from './pages/Landing';
import { AppBar, CssBaseline, Toolbar } from '@material-ui/core';
import logo from '../assets/logo_52.svg';
import FAQ from './pages/FAQ';
import PublicNavBar from './blocks/publicPages/PublicNavBar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

  },
  logoContainer: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none',
    color: 'none',
  },
  logoLink: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    maxWidth: 140,
  },
  logoImg: {
    width: 40,
    padding: 2,
  },
}));

export default function PublicPages() {
  const classes = useStyles();

  return (
    <Router>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar position="static" color='secondary'>
          <Toolbar>
            <div className={classes.logoContainer}>
              <Link className={`${classes.logoLink} ${classes.link}`} to="/">
                <img src={logo} alt={'logo'} className={classes.logoImg} />
              </Link>
            </div>

          <PublicNavBar />
            
          </Toolbar>
        </AppBar>
      </div>

      <Switch>
        <Route path="/admin-login/:username/:password" component={AdminAfterLogin} />
        <Route path="/login" component={Login} />
        <Route path="/faq" component={FAQ} />
        <Route path="/" component={Landing} />
      </Switch>
    </Router>
  );
}
